import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { indigo, blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { RegisterStrava } from './components/RegisterStrava';
import { Leaderboard } from './components/Leaderboard';

import { Container } from '@material-ui/core';
import Axios from 'axios';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: indigo,
  },
});

const useStyles = makeStyles((theme) => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  main: {
    height: '100%',
  },
}));

const App = () => {
  const classes = useStyles();
  const [stravaOauthUrl, setStravaOauthUrl] = useState('');

  useEffect(() => {
    Axios.get('https://us-central1-runstreaks-de565.cloudfunctions.net/api/stravaOauthUrl').then((result) => {
      setStravaOauthUrl(result.data);
    });
  }, []);

  const SignUp = () => {
    if (stravaOauthUrl === '') return (null)
    return (
      <a href={stravaOauthUrl}>Sign Up</a>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <main className={classes.main}>
            <h1>Run Streak Leaderboard</h1>
            <SignUp/>
            <Route path="/registerStrava" component={RegisterStrava} />
            <Container>
              <Leaderboard />
            </Container>
          </main>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
