import React, { useState, useEffect } from 'react';
import {
  Container,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  CircularProgress,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  main: {
    height: '100%',
  },
  table: {
    minWidth: 650,
  },
}));

interface RunStreak {
  id: string;
  firstName: string;
  lastName: string;
  profilePhotoURL: string;
  rank: number;
  longestStreak: number;
  currentStreak?: number;
}

//const apiHost = 'http://localhost:5001/runstreaks-de565/us-central1/api';
const apiHost = 'https://us-central1-runstreaks-de565.cloudfunctions.net/api';
export const Leaderboard = () => {
  const classes = useStyles();
  const [runstreaks, setRunStreaks] = useState(new Array<RunStreak>());

  useEffect(() => {
    axios.get(`${apiHost}/runstreaks`).then((result) => {
      const runstreaks: [RunStreak] = result.data;
      const sorted = runstreaks.slice().sort((a, b) => b.longestStreak - a.longestStreak)
      console.log(sorted)
      const ranked = runstreaks.map((runstreak) => {
        runstreak.rank = sorted.indexOf(runstreak) + 1
        return runstreak;
      })
      console.log(ranked);
      setRunStreaks(ranked.slice().sort((a, b) => a.rank - b.rank));
    });
  }, []);
  if (runstreaks.length < 1)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Longest Run Streak</TableCell>
            <TableCell>Current Run Streak</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {runstreaks.map((runstreak) => (
            <TableRow key={`${runstreak.firstName}-${runstreak.lastName}`}>
              <TableCell>{runstreak.rank}</TableCell>
              <TableCell>
                <Avatar src={runstreak.profilePhotoURL} /> {runstreak.firstName} {runstreak.lastName}
              </TableCell>
              <TableCell>{runstreak.longestStreak}</TableCell>
              <TableCell>{runstreak.currentStreak}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};