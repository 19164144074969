import React, { useEffect } from 'react';
import * as QueryString from 'query-string';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

//const apiHost = 'http://localhost:5001/runstreaks-de565/us-central1/api';
const apiHost = 'https://us-central1-runstreaks-de565.cloudfunctions.net/api';

export const RegisterStrava = (props: any) => {
  const params: any = QueryString.parse(props.location.search);
  useEffect(() => {
    const registerStrava = async () => {
      await axios.post(`${apiHost}/registerStrava`, {
        code: params.code,
      });
    };
    registerStrava();
  }, []);
  return <Redirect to="/" />;
};